import { Link } from "gatsby"
import React, { useRef } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"

import PikselFs from "../../../components/fullscreen/PikselFs"
import Seo from "../../../components/Seo"
import OluPikselGaleri from "../../../contents/Gallery/OluPikselGaleri"
import SvgLeftIcon from "../../../contents/Icons/SvgLeftIcon"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
import SvgPixelTest from "../../../contents/SVG/SvgPixelTest"
import EndIndicator from "../components/EndIndicator"
import StartIndicator from "../components/StartIndicator"
const DeadPixelTest = () => {
  const refPikselFs = useRef()

  return (
    <>
      <Seo
        lang="en"
        keywords="dead pixel test, stuck pixel test, stuck pixel repair, dead pixel repair, stuck pixel test, what is dead pixel, what is stuck pixel, what is stuck pixel, dead pixel stuck pixel difference, dead pixel test, pixel test, dead pixel, dead pixel, pixel test, stuck pixel, pixel repair online"
        title="Dead and Stuck Pixel Test"
        description="Test dead pixels and stuck pixels on your android, iphone phones, tablet, pc, smart television, monitor and computer screen on the free site without any installation."
      />
      <h1 className="text-center md:text-left">
        Dead Pixel or Stuck Pixel Test
      </h1>
      <p className="lead">
        You can detect <strong>dead pixels</strong> or{" "}
        <span className="font-bold text-purple-600">stuck pixels</span> on your
        screen, if any, by clicking the <code>Test Dead - Stuck Pixels</code>{" "}
        button below without any setup.
      </p>

      <div
        className="px-4 py-3 text-blue-900 bg-blue-100 border-t-4 border-blue-500 rounded-b shadow-md"
        role="alert"
      >
        <p className="font-bold">
          Before starting the Dead - Stuck Pixel test, you should consider:
        </p>
        <p className="">
          Before doing the dead pixel test, make sure your screen is clean and
          your environment is not too bright. While the dust on your screen can
          mislead you, excessive brightness can cause you to miss dead pixels.
        </p>
      </div>

      <div className="mt-10 text-center">
        <PurpleSolidButton
          text="Test Dead - Stuck Pixels"
          onClick={() => refPikselFs.current.open()}
        />
      </div>
      <h2>How Can I Test Dead Pixels or Stuck Pixels?</h2>
      <p>
        After clicking the <code>Test Dead - Stuck Pixels</code> button above,
        the site will go to full screen. Information is available on the screen
        that opens.
      </p>
      <p>
        To detect <strong>dead pixels</strong> or{" "}
        <span className="font-bold text-purple-600">stuck pixels</span>, you
        need to check the red, green, blue, black and white colors that occupy
        the full screen one by one.
      </p>

      <SvgPixelTest className="max-w-full px-3 md:px-10" />
      <p>
        To switch between colors while in full screen, you can click anywhere on
        the screen with your left mouse button or press the{" "}
        <SvgLeftIcon className="inline text-purple-700 fill-current" /> and{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" /> keys
        (left and right) of your keyboard.
      </p>
      <blockquote>
        Before start the dead pixel - stuck pixel test please care about warning
        above the blue information box on the top.
      </blockquote>
      <p>
        If you detect a dot or dots with a different color than the background
        color covering your screen, it means you have a{" "}
        <strong>dead pixel</strong> or a{" "}
        <span className="font-bold text-purple-600">stuck pixel</span> on your
        screen.
      </p>

      <blockquote>
        You can press{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        to exit the test.
      </blockquote>
      <p>
        You can view sample images of dead pixels or stuck pixels by clicking
        the <code>Dead - Stuck Pixel Samples</code> button below.
      </p>
      <OluPikselGaleri text="Dead - Stuck Pixel Samples" />
      <h2>What is Dead Pixel?</h2>
      <p>
        <strong>Dead Pixel</strong> is a pixel that doesn't work on your screen
        because of not getting energy. For this reason,{" "}
        <strong>dead pixels</strong> are usually black in color.
      </p>
      <p>
        Although <strong>dead pixels</strong> are mostly black they can be white
        on some displays.
      </p>
      <p>
        For more information on <strong>dead pixel</strong> and dead pixel
        repair, you can go to the relevant page by clicking the{" "}
        <code>Dead Pixel Repair</code> button below.
      </p>

      <div className="my-3 text-center">
        <Link
          to="/dead-pixel-fix"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Dead Pixel Repair</span>
        </Link>
      </div>

      <h2>What is Stuck Pixel?</h2>
      <p className="lead">
        Unlike <strong>dead pixels</strong>, stuck pixels still get energy, for
        this reason repairing stuck pixel repair is much easier.
      </p>
      <p>
        <span className="font-bold text-purple-600">Stuck pixels</span> are
        pixels that remain mostly in basic colors such as{" "}
        <span className="text-red-600">red</span>,{" "}
        <span className="text-green-400">green</span>,{" "}
        <span className="text-blue-600">blue</span> for reasons such as staying
        the same color for a long time, and reflect the same color continuously.
      </p>
      <p>
        For more information on stuck pixel and{" "}
        <strong>repairing stuck pixels</strong>, you can go to the corresponding
        page by clicking the <code>Stuck Pixel Repair</code> button below.
      </p>
      <div className="my-3 text-center">
        <Link
          to="/stuck-pixel-fix"
          style={{ textDecoration: "none" }}
          className="focus:outline-none text-white py-2.5 px-5 rounded-md bg-purple-500 hover:bg-purple-600 hover:shadow-lg text-base md:text-lg"
        >
          <span className="text-white">Stuck Pixel Repair</span>
        </Link>
      </div>
      <PikselFs
        ref={refPikselFs}
        startIndicator={<StartIndicator />}
        endIndicator={
          <EndIndicator
            closeTest={() => refPikselFs.current.close()}
            resetTest={() => refPikselFs.current.reset()}
          />
        }
      />
    </>
  )
}
export default DeadPixelTest
