import React, { memo } from "react"
import PurpleSolidButton from "../../../components/Buttons/PurpleSolidButton"
import RedSolidButton from "../../../components/Buttons/RedSolidButton"
const EndIndicator = ({ closeTest, resetTest }) => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        <span role="img" aria-label="congratulations">
          🎉
        </span>
        You have completed successfully the dead pixel test
        <span role="img" aria-label="congratulations">
          🎉
        </span>
      </p>
      <p className="lead">
      I hope there are no dead pixels on your screen.
      </p>
      <p className="lead">
      You can press{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        to exit the test.
      </p>
      <div>
        <RedSolidButton
          text="Exit"
          onClick={closeTest}
          style={{ marginRight: "8px" }}
        />
        <PurpleSolidButton text="Retest" onClick={resetTest} />
      </div>
    </div>
  )
}
export default memo(EndIndicator)
