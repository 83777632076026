import React, { memo } from "react"
import SvgRightIcon from "../../../contents/Icons/SvgRightIcon"
const StartIndicator = () => {
  return (
    <div className="prose-sm prose text-center sm:prose lg:prose-lg xl:prose-xl">
      <p className="lead">
        You can start the dead pixel test by clicking anywhere on the screen or
        by pressing the{" "}
        <SvgRightIcon className="inline text-purple-700 fill-current" /> key
        (right) of your keyboard.
      </p>
      <p className="lead">
        You can press{" "}
        <span className="px-2 py-1 text-sm font-bold text-red-800 bg-red-100 border-red-300 rounded-sm shadow-sm">
          ESC
        </span>{" "}
        to exit the test.
      </p>
    </div>
  )
}
export default memo(StartIndicator)
